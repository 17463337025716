import React from "react"
import { StaticQuery, graphql } from 'gatsby'
import g from "glamorous"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"

export default ({ children }) => (
    <StaticQuery
        query={graphql`
       query  {
         allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
           totalCount
           edges {
             node {
               id
               frontmatter {
                 title
                 date(formatString: "DD MMMM, YYYY")
                 tags
               }
               fields {
                 slug
               }
               excerpt
             }
           }
         }
       }
        `}
            render={data => (
                <div id="posts">
                    <u><h1>
                        Blog
                    </h1></u>
                    <h4>
                        {data.allMarkdownRemark.totalCount} Posts
                    </h4>
                    {data.allMarkdownRemark.edges.map(({ node }) =>
                        <div id={node.frontmatter.title.replace(/ /g,'-')} key={node.id} className="blogpost">
                            <Link
                                to={node.fields.slug}
                                css={{ textDecoration: `none`, color: `inherit` }}
                            >
                                <g.H3 marginBottom={rhythm(1 / 4)}>
                                    {node.frontmatter.title}{" "}
                                    <g.Span color="#333" fontSize="16px"><br/>{node.frontmatter.date}</g.Span>
                                    <g.Span color="#ec407a" fontSize="16px"> <br/>{node.frontmatter.tags}</g.Span>
                                </g.H3>
                                <p>
                                    {node.excerpt}
                                </p>
                            </Link>
                        </div>
                    )}
                </div>
            )
            }
        />
)
